@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-card-item{
    cursor: pointer;

    &.flex-row {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    .card-item-image {
      width: 100%;
      height: 144px;
      border-radius: 16px;
      object-fit: cover;

      &.flex-row {
        width: 96px;
        height: 96px;
      }
    }

    .card-item-content {
      display: flex;
      flex-direction: column;

      .title {
        font-size: mat.font-size($typography, text-md-600);
        font-weight: mat.font-weight($typography, text-md-600);
        letter-spacing: mat.letter-spacing($typography, text-md-600);
        line-height: mat.line-height($typography, text-md-600);
        color: mat.get-color-from-palette($background, nm-card-item-title);
      }

      .description {
        font-size: mat.font-size($typography, text-sm-400);
        font-weight: mat.font-weight($typography, text-sm-400);
        letter-spacing: mat.letter-spacing($typography, text-sm-400);
        line-height: mat.line-height($typography, text-sm-400);
        color: mat.get-color-from-palette($background, nm-card-item-desc);
      }
    }
  }
}
