@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-option {
    display: flex;
    flex-direction: row;
    gap: 4px;

    font-size: mat.font-size($typography, text-sm-400);
    font-weight: mat.font-weight($typography, text-sm-400);
    letter-spacing: mat.letter-spacing($typography, text-sm-400);
    line-height: mat.line-height($typography, text-sm-400);
    color: mat.get-color-from-palette($background, nm-option);
  }
}
