@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-booking {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .booking-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;

    img {
      width: 96px;
      height: 96px;
    }

    nm-svg-icon {
      color: mat.get-color-from-palette($background, nm-booking-banner-icon);
    }

    .booking-banner-title {
      color: mat.get-color-from-palette($background, nm-booking-banner-title);
      font-size: mat.font-size($typography, display-xs-600);
      font-weight: mat.font-weight($typography, display-xs-600);
      letter-spacing: mat.letter-spacing($typography, display-xs-600);
      line-height: mat.line-height($typography, display-xs-600);
    }

    .booking-banner-desc {
      color: mat.get-color-from-palette($background, nm-booking-banner-desc);
      font-size: mat.font-size($typography, text-md-400);
      font-weight: mat.font-weight($typography, text-md-400);
      letter-spacing: mat.letter-spacing($typography, text-md-400);
      line-height: mat.line-height($typography, text-md-400);
    }
  }

  .booking-infos {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .booking-title {
      color: mat.get-color-from-palette($background, nm-booking-title);
      font-size: mat.font-size($typography, text-sm-500);
      font-weight: mat.font-weight($typography, text-sm-500);
      letter-spacing: mat.letter-spacing($typography, text-sm-500);
      line-height: mat.line-height($typography, text-sm-500);
    }

    .booking-info {
      display: flex;
      flex-direction: row;
      gap: 4px;

      .booking-text {
        color: mat.get-color-from-palette($background, nm-booking-text);
        font-size: mat.font-size($typography, text-xs-400);
        font-weight: mat.font-weight($typography, text-xs-400);
        letter-spacing: mat.letter-spacing($typography, text-xs-400);
        line-height: mat.line-height($typography, text-xs-400);
        overflow: hidden;

        &.booking-desc {
          color: mat.get-color-from-palette($background, nm-booking-desc);
          flex: 1;
          word-wrap: break-word;
        }
      }
    }
  }

  .logo-block {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    justify-content: center;

    .logo-text {
      color: #707070;
      font-size: mat.font-size($typography, text-md-400);
      font-weight: mat.font-weight($typography, text-md-400);
      letter-spacing: mat.letter-spacing($typography, text-md-400);
      line-height: mat.line-height($typography, text-md-400);
    }
  }

  .layout-title {
    color: mat.get-color-from-palette($background, nm-layout-title);
    font-size: mat.font-size($typography, text-xl-600);
    font-weight: mat.font-weight($typography, text-xl-600);
    letter-spacing: mat.letter-spacing($typography, text-xl-600);
    line-height: mat.line-height($typography, text-xl-600);
  }

  .layout-picture {
    height: 240px;
    min-height: 240px;
    display: flex;
    align-items: flex-end;
    padding: 16px;

    background-size: cover;
    background-position: center;

    .layout-store {
      color: mat.get-color-from-palette($background, nm-layout-store);
      font-size: mat.font-size($typography, display-sm-400);
      font-weight: mat.font-weight($typography, display-sm-400);
      letter-spacing: mat.letter-spacing($typography, display-sm-400);
      line-height: mat.line-height($typography, display-sm-400);
    }

    .layout-description {
      color: mat.get-color-from-palette($background, nm-layout-store);
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
    }
  }
}


.booking {
  display: flex;
  height: 100%;
  padding: 0 16px;
  flex-direction: column;
  justify-content: space-between;

  .booking-form {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;

    max-width: 632px;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
  }

  nm-input {
    width: 100%;
  }
}

.booking-done {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.layout-title-block {
  display: flex;
  padding: 0 16px;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  height: 64px;
  min-height: 64px;
}
