@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .profile-page {
    height: 100%;

    .profile {
      padding: 16px 16px 0 16px;
      display: flex;
      height: calc(100% - 56px);
      flex-direction: column;
      justify-content: space-between;

      .profile-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .gender-ratio{
          display: flex;
          flex-direction: column;
          gap: 12px;

          .gender{
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            .gender-text {
              font-size: mat.font-size($typography, text-sm-500);
              font-weight: mat.font-weight($typography, text-sm-500);
              letter-spacing: mat.letter-spacing($typography, text-sm-500);
              line-height: mat.line-height($typography, text-sm-500);
              color: mat.get-color-from-palette($background, nm-gender);
            }
          }
        }
      }

      .save-profile{
        padding: 16px 0;
      }
    }
  }
}
