@use '@angular/material' as mat;
@use 'sass:map';

@use 'app/shared/component/input/input.component';
@use 'app/shared/component/button/button.component';
@use 'app/shared/component/icon-button/icon-button.component';
@use 'app/shared/component/radio/radio.component';
@use 'app/shared/component/checkbox/checkbox.component';
@use 'app/shared/component/form-field/form-field.component';
@use 'app/shared/component/control-label/control-label.component';
@use 'app/shared/component/divider/divider.component';
@use 'app/shared/component/chip/chip.component';
@use 'app/shared/component/control-hint/control-hint.component';
@use 'app/shared/component/avatar/avatar.component';
@use 'app/shared/component/alert/alert.component';
@use 'app/shared/component/chips/chips.component';
@use 'app/shared/component/card-banner/card-banner.component';
@use 'app/shared/component/title-h1/title-h1.component';
@use 'app/shared/component/title-h2/title-h2.component';
@use 'app/shared/component/list-item/list-item.component';
@use 'app/shared/component/datepicker/datepicker.component';
@use 'app/shared/component/tag/tag.component';
@use 'app/shared/component/option/option.component';
@use 'app/shared/component/card-item/card-item.component';

@use 'app/modules/error/page/page-404/page-404.component';
@use 'app/modules/pages/main-page/main-page.component';
@use 'app/modules/pages/profile/profile.component';
@use 'app/modules/pages/points/points.component';
@use 'app/modules/pages/booking/booking.component';
@use 'app/modules/pages/store/store.component';

@import 'assets/style/media-vars';
@import '~normalize.css';

@import 'assets/style/fonts.css';
@import 'assets/style/typography.scss';
@import 'assets/style/colors.scss';
@import 'assets/style/theme.scss';
@import 'assets/style/app';

@mixin nomia-theme($theme) {
  @include input.theme($theme);
  @include button.theme($theme);
  @include icon-button.theme($theme);
  @include chip.theme($theme);
  @include control-hint.theme($theme);
  @include radio.theme($theme);
  @include checkbox.theme($theme);
  @include form-field.theme($theme);
  @include control-label.theme($theme);
  @include divider.theme($theme);
  @include avatar.theme($theme);
  @include alert.theme($theme);
  @include chips.theme($theme);
  @include card-banner.theme($theme);
  @include title-h1.theme($theme);
  @include title-h2.theme($theme);
  @include list-item.theme($theme);
  @include datepicker.theme($theme);
  @include tag.theme($theme);
  @include option.theme($theme);
  @include card-item.theme($theme);

  @include profile.theme($theme);
  @include points.theme($theme);
  @include main-page.theme($theme);
  @include page-404.theme($theme);
  @include booking.theme($theme);
  @include store.theme($theme);
}

@include nomia-theme($theme);
@include mat.all-component-themes($theme);
@include mat.core($nomia-typography);

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family:'Inter', sans-serif !important;
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100svh;

  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  background-color: mat.get-color-from-palette($background, main-background);

  &.points-page {
    background-color: mat.get-color-from-palette($background, points-background);
  }
}

a,p,span,h1,h2,h3,h4,h5,h6,li,input,textarea {
  font-family:'Inter', sans-serif !important;
}

.application-loading-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgb(0, 92, 188);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
