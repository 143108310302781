@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .title-h2 {
    height: 44px;
    padding: 16px 16px 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    nm-button .nm-button{
      font-size: mat.font-size($typography, text-md-400);
      font-weight: mat.font-weight($typography, text-md-400);
      letter-spacing: mat.letter-spacing($typography, text-md-400);
      line-height: mat.line-height($typography, text-md-400);
      color: mat.get-color-from-palette($background, nm-title-h2-button) !important;
    }

    .text-block {
      display: flex;
      flex-direction: column;

      .title {
        color: mat.get-color-from-palette($background, nm-title);
        font-size: mat.font-size($typography, text-xl-600);
        font-weight: mat.font-weight($typography, text-xl-600);
        letter-spacing: mat.letter-spacing($typography, text-xl-600);
        line-height: mat.line-height($typography, text-xl-600);
      }

      .description {
        color: mat.get-color-from-palette($background, nm-title);
        font-size: mat.font-size($typography, text-xs-400);
        font-weight: mat.font-weight($typography, text-xs-400);
        letter-spacing: mat.letter-spacing($typography, text-xs-400);
        line-height: mat.line-height($typography, text-xs-400);
      }
    }
  }
}
