@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-store-page {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .img-title {
      height: 210px;
      display: flex;
      align-items: flex-end;
      background-size: cover;
      background-position: center;

      .title {
        width: 100%;
        padding: 20px 16px;
        font-size: mat.font-size($typography, display-xs-600);
        font-weight: mat.font-weight($typography, display-xs-600);
        letter-spacing: mat.letter-spacing($typography, display-xs-600);
        line-height: mat.line-height($typography, display-xs-600);
        color: mat.get-color-from-palette($background, nm-store);
      }
    }

    .text{
      padding: 0 16px;
      font-size: mat.font-size($typography, text-sm-400);
      font-weight: mat.font-weight($typography, text-sm-400);
      letter-spacing: mat.letter-spacing($typography, text-sm-400);
      line-height: mat.line-height($typography, text-sm-400);
      color: mat.get-color-from-palette($background, nm-store);
    }
  }
}
