@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .title-h1 {
    height: 56px;
    padding: 20px 16px 4px 16px;

    color: mat.get-color-from-palette($background, nm-title);
    font-size: mat.font-size($typography, display-xs-600);
    font-weight: mat.font-weight($typography, display-xs-600);
    letter-spacing: mat.letter-spacing($typography, display-xs-600);
    line-height: mat.line-height($typography, display-xs-600);
  }
}
