@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-page-404 {
    display: flex;
    justify-content: center;

    .page-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 360px;
      padding-top: 164px;

      .error-text {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .error-code {
          color: mat.get-color-from-palette($foreground, nm-error-title);

          font-size: mat.font-size($typography, display-xl-600);
          font-weight: mat.font-weight($typography, display-xl-600);
          line-height: mat.line-height($typography, display-xl-600);
          letter-spacing: mat.letter-spacing($typography, display-xl-600);
        }

        .error-title {
          color: mat.get-color-from-palette($foreground, nm-error-title);

          font-size: mat.font-size($typography, display-xs-600);
          font-weight: mat.font-weight($typography, display-xs-600);
          line-height: mat.line-height($typography, display-xs-600);
          letter-spacing: mat.letter-spacing($typography, display-xs-600);
        }

        .error-description {
          color: mat.get-color-from-palette($foreground, nm-error-description);

          font-size: mat.font-size($typography, text-sm-400);
          font-weight: mat.font-weight($typography, text-sm-400);
          line-height: mat.line-height($typography, text-sm-400);
          letter-spacing: mat.letter-spacing($typography, text-sm-400);
        }
      }
    }
  }
}
