@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

}

.main-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;

  .navigation-menu {
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 0 16px;
  }

  .stores {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 0 16px;
  }
}
