@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme ($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  $range-background: mat.get-color-from-palette($background, nm-datepicker-range);
  $range-compare-background: mat.get-color-from-palette($background, nm-datepicker-range-compare);

  .mat-calendar {
    font-family:'Inter', sans-serif !important;
  }

  .mat-datepicker-content-touch .mat-datepicker-content-container {
    width: 328px !important;
    height: fit-content !important;
    top: 121px;
    left: 24px;
    border-radius: 28px;
  }

  .mat-datepicker-content {
    background-color: mat.get-color-from-palette($background, nm-datepicker-content) !important;
    box-shadow: mat.get-color-from-palette($foreground, nm-filter-panel-shadow) !important;
    border-radius: 28px !important;

    .mat-datepicker-content-container:has(.filter-period-back) {
      position: relative;
      padding-top: 56px;
    }

    .mat-datepicker-actions {
      flex-direction: column;
      padding: 8px 12px 12px 12px;
      row-gap: 8px;

      .controls {
        display: flex;
        column-gap: 8px;
        width: 100%;
        justify-content: flex-end;
      }

      .back {
        position: absolute;
        top: 8px;
        left: 0;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 4px 8px;
        margin: 0;
        column-gap: 8px;
        cursor: default;

        .text {
          color: mat.get-color-from-palette($foreground, nm-filter-period-text);
          font-size: mat.font-size($typography, text-sm-500);
          font-weight: mat.font-weight($typography, text-sm-500);
          letter-spacing: mat.letter-spacing($typography, text-sm-500);
          line-height: mat.line-height($typography, text-sm-500);
        }
      }
    }

    .mat-calendar-body-selected {
      background: mat.get-color-from-palette($background, nm-datepicker-selected);

      @nest .mat-calendar-body-disabled > & {
        opacity: 0.4;
      }
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
      border-color: mat.get-color-from-palette($background, nm-datepicker-today-border);
    }

    .mat-calendar.nm-datepicker-calendar {
      color: mat.get-color-from-palette($foreground, nm-datepicker);

      .mat-calendar-content {
        padding: 0 12px;
      }

      .mat-calendar-body-cell-content {
        color: mat.get-color-from-palette($foreground, nm-datepicker);
        font-size: mat.font-size($typography, nm-datepicker-cell);
        font-weight: mat.font-weight($typography, nm-datepicker-cell);
        letter-spacing: mat.letter-spacing($typography, nm-datepicker-cell);
        line-height: mat.line-height($typography, nm-datepicker-cell);

        &.mat-calendar-body-selected {
          color: mat.get-color-from-palette($foreground, nm-datepicker-selected);
        }
      }

      .mat-calendar-body {
        .mat-calendar-body-label {
          visibility: hidden;

          &[colspan='7'] {
            display: none;
          }
        }

        .mat-calendar-body-in-range::before {
          background: $range-background;
        }

        .mat-calendar-body-comparison-identical,
        .mat-calendar-body-in-comparison-range::before {
          background: $range-compare-background;
        }

        .mat-calendar-body-comparison-bridge-start::before,
        [dir='rtl'] .mat-calendar-body-comparison-bridge-end::before {
          background: linear-gradient(to right, $range-background 50%, $range-compare-background 50%);
        }

        .mat-calendar-body-comparison-bridge-end::before,
        [dir='rtl'] .mat-calendar-body-comparison-bridge-start::before {
          background: linear-gradient(to left, $range-background 50%, $range-compare-background 50%);
        }

        .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
        .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
          background: mat.get-color-from-palette($background, nm-datepicker-range-overlap);
        }

        .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
        .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
          background: mat.get-color-from-palette($background, nm-datepicker-range-selected);
        }

        .cdk-keyboard-focused, .cdk-program-focused {
          .mat-calendar-body-cell-content:not(.mat-calendar-body-selected,
        .mat-calendar-body-comparison-identical) {
            opacity: 0.3 !important;
          }
        }

        @media (hover: hover) {
          .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected,
        .mat-calendar-body-comparison-identical) {
            background-color: mat.get-color-from-palette($background, nm-datepicker-hovered);
          }
        }

        @media (hover: hover) {
          .mat-calendar-body-cell.mat-calendar-body-in-range:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected,
        .mat-calendar-body-comparison-identical) {
            background-color: mat.get-color-from-palette($background, nm-datepicker-range-hovered);
          }
        }

        @media (hover: hover) {
          .mat-calendar-body-cell.mat-calendar-body-in-comparison-range:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected,
        .mat-calendar-body-comparison-identical) {
            background-color: mat.get-color-from-palette($background, nm-datepicker-range-compare-hovered);
          }
        }

        @media (hover: hover) {
          .mat-calendar-body-cell.mat-calendar-body-in-range.mat-calendar-body-in-comparison-range:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected,
        .mat-calendar-body-comparison-identical) {
            background-color: mat.get-color-from-palette($background, nm-datepicker-range-overlap-hovered);
          }
        }
      }

      &.nm-datepicker-year-view {
        .mat-calendar-body .mat-calendar-body-label {
          display: none;
        }
      }

      .mat-calendar-table-header-divider {
        display: none;
      }

      .mat-calendar-table-header {
        color: mat.get-color-from-palette($foreground, nm-datepicker-days);
        font-size: mat.font-size($typography, nm-datepicker-days);
        font-weight: mat.font-weight($typography, nm-datepicker-days);
        letter-spacing: mat.letter-spacing($typography, nm-datepicker-days);
        line-height: mat.line-height($typography, nm-datepicker-days);
        text-transform: uppercase;

        th {
          padding: 12px 0;
        }
      }

      .mat-calendar-header {;
        padding: 8px 12px 8px 16px;

        .mat-calendar-controls {
          display: flex;
          padding: 0;
          margin: 0;
          color: mat.get-color-from-palette($foreground, nm-datepicker);
          font-size: mat.font-size($typography, nm-datepicker-control);
          font-weight: mat.font-weight($typography, nm-datepicker-control);
          letter-spacing: mat.letter-spacing($typography, nm-datepicker-control);
          line-height: mat.line-height($typography, nm-datepicker-control);

          .mat-button-ripple, .mat-button-focus-overlay {
            display: none;
          }

          .mat-calendar-previous-button, .mat-calendar-next-button {
            color: mat.get-color-from-palette($foreground, nm-datepicker);
          }

          .mat-calendar-arrow {
            fill: mat.get-color-from-palette($foreground, nm-datepicker);
          }
        }

        .mat-calendar-period-button {
          padding: 0;
        }
      }
    }

    .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
      background-color: initial;
    }

    .range-controls {
      margin-bottom: 16px;
    }
  }

  .nm-datepicker {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    .calendar-icon {
      cursor: pointer;
    }

    .nm-form-field--disabled {
      .calendar-icon {
        cursor: auto;
      }
    }

    &.opened {
      .calendar-icon {
        color: mat.get-color-from-palette($foreground, nm-datepicker-open);
      }
    }
  }
}
