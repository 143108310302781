@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .points-page{
    display: flex;
    flex-direction: column;
    height: 100vh;

    .points-block {
      display: flex;
      gap: 4px;
      padding: 24px 0;
      justify-content: center;
      align-items: center;

      .points-text {
        font-size: mat.font-size($typography, display-md-600);
        font-weight: mat.font-weight($typography, display-md-600);
        letter-spacing: mat.letter-spacing($typography, display-md-600);
        line-height: mat.line-height($typography, display-md-600);
        color: mat.get-color-from-palette($background, nm-points);
      }
    }

    .history-title {
      padding: 16px 16px 8px 16px;

      font-size: mat.font-size($typography, text-md-600);
      font-weight: mat.font-weight($typography, text-md-600);
      letter-spacing: mat.letter-spacing($typography, text-md-600);
      line-height: mat.line-height($typography, text-md-600);
      color: mat.get-color-from-palette($background, nm-points);
    }

    .empty-history {
      padding: 8px 24px 16px 16px;

      font-size: mat.font-size($typography, text-md-400);
      font-weight: mat.font-weight($typography, text-md-400);
      letter-spacing: mat.letter-spacing($typography, text-md-400);
      line-height: mat.line-height($typography, text-md-400);
      color: mat.get-color-from-palette($background, nm-points);
    }

    .history {
      overflow-y: auto;
      flex: 1 1 auto;
      scrollbar-width: none;
    }
  }
}
