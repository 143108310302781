@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .list-item {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 8px 16px;

    .list-item-title {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .title-container {
        display: flex;
        flex-direction: column;

        .title {
          font-size: mat.font-size($typography, text-md-600);
          font-weight: mat.font-weight($typography, text-md-600);
          letter-spacing: mat.letter-spacing($typography, text-md-600);
          line-height: mat.line-height($typography, text-md-600);
          color: mat.get-color-from-palette($background, nm-list-item);
        }

        .description, .descriptionUp {
          font-size: mat.font-size($typography, text-xs-400);
          font-weight: mat.font-weight($typography, text-xs-400);
          letter-spacing: mat.letter-spacing($typography, text-xs-400);
          line-height: mat.line-height($typography, text-xs-400);
          color: mat.get-color-from-palette($background, nm-list-item);
        }
      }
    }

    .list-item-action {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      .text {
        font-size: mat.font-size($typography, text-md-400);
        font-weight: mat.font-weight($typography, text-md-400);
        letter-spacing: mat.letter-spacing($typography, text-md-400);
        line-height: mat.line-height($typography, text-md-400);
        color: mat.get-color-from-palette($background, nm-list-item);
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 4px 8px 4px 8px;
    border-radius: 16px;

    font-size: mat.font-size($typography, text-xs-400);
    font-weight: mat.font-weight($typography, text-xs-400);
    letter-spacing: mat.letter-spacing($typography, text-xs-400);
    line-height: mat.line-height($typography, text-xs-400);


    &.status-blue {
      color: mat.get-color-from-palette($background, status-blue-text);
      background-color: mat.get-color-from-palette($background, status-blue);
    }
  }
}
