@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nm-tag {
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    height: 20px;
    padding: 2px 8px 2px 8px;
    gap: 4px;
    border-radius: 999px;

    font-size: mat.font-size($typography, text-sm-500);
    font-weight: mat.font-weight($typography, text-sm-500);
    letter-spacing: mat.letter-spacing($typography, text-sm-500);
    line-height: mat.line-height($typography, text-sm-500);

    &.blue {
      color: mat.get-color-from-palette($background, nm-tag-blue);
      background-color: mat.get-color-from-palette($background, nm-tag-blue-background);
    }

    &.green {
      color: mat.get-color-from-palette($background, nm-tag-green);
      background-color: mat.get-color-from-palette($background, nm-tag-green-background);
    }
  }
}
