@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $typography: mat.get-typography-config($theme);

  .nav-item {
    width: 144px;
    min-width: 144px;
    height: 160px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #1E1E1E;
    cursor: pointer;

    .nav-text-block {
      display: flex;
      flex-direction: column;

      .nav-big-title {
        color: mat.get-color-from-palette($background, nm-nav-item);
        font-size: mat.font-size($typography, display-xs-500);
        font-weight: mat.font-weight($typography, display-xs-500);
        letter-spacing: mat.letter-spacing($typography, display-xs-500);
        line-height: mat.line-height($typography, display-xs-500);
      }

      .nav-title {
        color: mat.get-color-from-palette($background, nm-nav-item);
        font-size: mat.font-size($typography, text-md-600);
        font-weight: mat.font-weight($typography, text-md-600);
        letter-spacing: mat.letter-spacing($typography, text-md-600);
        line-height: mat.line-height($typography, text-md-600);
      }

      .nav-description {
        color: mat.get-color-from-palette($background, nm-nav-item);
        font-size: mat.font-size($typography, text-sm-400);
        font-weight: mat.font-weight($typography, text-sm-400);
        letter-spacing: mat.letter-spacing($typography, text-sm-400);
        line-height: mat.line-height($typography, text-sm-400);
      }
    }
  }
}
